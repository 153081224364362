import React, { Fragment, useEffect } from 'react'
import Helmet from 'react-helmet'
import { Articles, SectionMeta } from 'ion-article-cmp'
import WingBanners from '../components/WingBanners'
import { getSectionLabel } from 'ion-sections'
import Ad from '../components/Ad'
import { FORMATS, MAPPINGS } from '../components/AdFormats'
import Sidebar from '../components/Sidebar'
import NoImage from '../static/no-image.png'
import ArticleCard from '../components/ArticleCard'
import NotFound from './NotFound'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { fetchAuthor } from '../../store/author'
import { connect } from 'react-redux'
import AuthorBio from '../components/AuthorBio'

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250], 'fluid']],
  [[640, 0], [[460, 60], 'fluid']],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], 'fluid']]
]

const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

const AuthorArticles = ({ articles, sectionLabel, noImage, moreButton }) => {
  const title = 'Articles by ' + sectionLabel
  return (
    <>
      <Helmet title={title}>
        <meta property='og:title' content={title} />
        <meta itemProp='headline' content={title} />
        <meta name='twitter:title' content={title} />

      </Helmet>
      <h1>{title}</h1>
      {articles.map((article) => {
        return (
          <ArticleCard key={article.contentKey} article={article} noImage={noImage} />
        )
      })}
      {moreButton}
    </>
  )
}

const AuthorSectionPage = (props) => {
  useEffect(() => {
    if (props.authorSlug) {
      props.fetchAuthor(props.authorSlug)
    }
  }, [props.authorSlug, props.fetchAuthor])

  return (
    <>
      <Helmet
        titleTemplate='%s'
        title={props.title || getSectionLabel(props.section)}
      >
        <meta property='og:type' content='section' />
        <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
        <meta itemProp='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
        <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
        <meta property='og:url' content={props.url} />
        <meta property='keywords' content={props.keywords} />
        <meta name='twitter:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
      </Helmet>
      <WingBanners useSmall={props.useSmall} isConnected={props.isConnected} location={props.location} onSlotRenderEndedLeft={props.onSlotRenderEndedLeft} onSlotRenderEndedRight={props.onSlotRenderEndedRight} mappingLeft={Wing} mappingRight={Wing} />
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert-leaderboard' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-1' targeting={{ leaderboard: '1' }} collapseEmptyDiv className='advert-leaderboard-top' mapping={Leaderboard} {...FORMATS.leaderboard} />
      <div className='wrapper article-listing'>
        {props.isPage0 &&
          <div className='author article-list'>
            {props.author && props.author.author &&
              <AuthorBio {...props.author.author} noImage={NoImage} />}
            <Articles {...props} component={AuthorArticles} section={props.section} count={15} pageSize={14} noImage={NoImage} notFound={NotFound} withMore />
          </div>}
        {!props.isPage0 &&
          <Articles className='article-list paged' {...props} component={AuthorArticles} section={props.section} page0Limit={15} pageSize={14} noImage={NoImage} notFound={NotFound} withMore />}
        <Sidebar {...props} />
      </div>
      <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-3' targeting={{ leaderboard: '3' }} collapseEmptyDiv className='advert-leaderboard-top' mapping={Leaderboard} {...FORMATS.leaderboard} />
      <SectionMeta {...props} />
    </>
  )
}

AuthorSectionPage.defaultProps = {
  section: 'authors/molife-kumona'
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchAuthor }, dispatch)

export default withRouter(connect((state) => ({ articles: state.articles, author: state.author }), mapDispatchToProps)(AuthorSectionPage))
