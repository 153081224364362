import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'
import Helmet from 'react-helmet'
import { getSectionLabel, url2section } from 'ion-sections'
import qs from 'qs'

import DefaultSectionPage from '../pages/DefaultSectionPage'
import Article from '../pages/Article'
import NotFound from '../pages/NotFound'
import AuthorSectionPage from '../pages/AuthorSectionPage'

const DefaultSection = (props) => {
  const params = props.location ? qs.parse(props.location.search, { ignoreQueryPrefix: true }) : { page: 0 }
  const isPage0 = (params.page || 0) < 2

  return (
    <>
      <Helmet titleTemplate={props.titleTemplate} />
      <Switch>
        <Route path={props.match.url + '/:section([a-zA-Z0-9-]+)/'} strict exact render={p => <Redirect to={props.match.url + '/' + p.match.params.section} />} />
        {props.match.url === props.location.pathname && (
          <Switch>
            <Route
              path='/news' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='news'
                  relatedSections={[{ id: 'news/western-cape' }, { id: 'news/national' }, { id: 'news/international' }, { id: 'news/business' }, { id: 'news/politics' }]}
                  title='News | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/news/western-cape' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='news/western-cape'
                  relatedSections={[{ id: 'news/national' }, { id: 'news/international' }, { id: 'news/business' }, { id: 'news/politics' }]}
                  title='Western Cape | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/news/national' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='news/national'
                  relatedSections={[{ id: 'news/western-cape' }, { id: 'news/international' }, { id: 'news/business' }, { id: 'news/politics' }]}
                  title='National | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/news/international' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='news/international'
                  relatedSections={[{ id: 'news/western-cape' }, { id: 'news/national' }, { id: 'news/business' }, { id: 'news/politics' }]}
                  title='International | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/news/business' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='news/business'
                  relatedSections={[{ id: 'news/western-cape' }, { id: 'news/national' }, { id: 'news/international' }, { id: 'news/politics' }]}
                  title='Business | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/news/politics' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='news/politics'
                  relatedSections={[{ id: 'news/western-cape' }, { id: 'news/national' }, { id: 'news/international' }, { id: 'news/business' }]}
                  title='Politics | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/sport' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='sport'
                  relatedSections={[{ id: 'sport/highlights' }, { id: 'sport/cricket' }, { id: 'sport/rugby' }, { id: 'sport/soccer' }]}
                  title='Sport | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/sport/highlights' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='sport/highlights'
                  relatedSections={[{ id: 'sport/cricket' }, { id: 'sport/rugby' }, { id: 'sport/soccer' }]}
                  title='Sport | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/sport/cricket' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='sport/cricket'
                  relatedSections={[{ id: 'sport/highlights' }, { id: 'sport/rugby' }, { id: 'sport/soccer' }]}
                  title='Cricket | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/sport/rugby' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='sport/rugby'
                  relatedSections={[{ id: 'sport/highlights' }, { id: 'sport/cricket' }, { id: 'sport/soccer' }]}
                  title='Rugby | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/sport/soccer' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='sport/soccer'
                  relatedSections={[{ id: 'sport/highlights' }, { id: 'sport/cricket' }, { id: 'sport/rugby' }]}
                  title='Soccer | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Lifestyle & Entertainment | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/celebrity' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/celebrity'
                  relatedSections={[{ id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Celebrity | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/entertainment' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/entertainment'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Entertainment | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/cape-spy' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/cape-spy'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Cape Spy | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/cinema' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/cinema'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Cinema | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/gadget-games' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/gadget-games'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Gadgets & Games | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/tonight' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/tonight'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Tonight | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/recipes' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/recipes'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Recipies | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/lifestyle' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/lifestyle'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Lifestyle | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/travel' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/travel'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }]}
                  title='Travel | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/parenting' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/parenting'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Parenting | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/health-beauty' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/health-beauty'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Health & Beauty | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/horoscope' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/horoscope'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Horoscope | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/lifestyle-entertainment/photo-of-the-day' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='lifestyle-entertainment/photo-of-the-day'
                  relatedSections={[{ id: 'lifestyle-entertainment/celebrity' }, { id: 'lifestyle-entertainment/entertainment' }, { id: 'lifestyle-entertainment/recipes' }, { id: 'lifestyle-entertainment/horoscope' }, { id: 'lifestyle-entertainment/cape-spy' }, { id: 'lifestyle-entertainment/travel' }]}
                  title='Photo of the Day | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion'
                  relatedSections={[{ id: 'opinion/sport' }, { id: 'opinion/motoring' }, { id: 'opinion/current-affairs' }, { id: 'opinion/lifestyle' }]}
                  title='Opinion | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/sport' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/sport'
                  relatedSections={[{ id: 'opinion/sport/dudley-carstens' }, { id: 'opinion/sport/matthew-marcus' }, { id: 'opinion/sport/nick-feinberg' }, { id: 'opinion/sport/julia-stuart' }]}
                  title='Sport | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/sport/dudley-carstens' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/sport/dudley-carstens'
                  relatedSections={[{ id: 'opinion/sport/matthew-marcus' }, { id: 'opinion/sport/nick-feinberg' }, { id: 'opinion/sport/julia-stuart' }]}
                  title='Dudley Carstens | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/sport/matthew-marcus' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/sport/matthew-marcus'
                  relatedSections={[{ id: 'opinion/sport/dudley-carstens' }, { id: 'opinion/sport/nick-feinberg' }, { id: 'opinion/sport/julia-stuart' }]}
                  title='Matthew Marcus | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/sport/nick-feinberg' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/sport/nick-feinberg'
                  relatedSections={[{ id: 'opinion/sport/dudley-carstens' }, { id: 'opinion/sport/matthew-marcus' }, { id: 'opinion/sport/julia-stuart' }]}
                  title='Nick Feinberg | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/sport/julia-stuart' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/sport/julia-stuart'
                  relatedSections={[{ id: 'opinion/sport/dudley-carstens' }, { id: 'opinion/sport/matthew-marcus' }, { id: 'opinion/sport/nick-feinberg' }]}
                  title='Julia Stuart | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/motoring' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/motoring'
                  relatedSections={[{ id: 'opinion/motoring/bobby-nitro' }]}
                  title='Motoring | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/motoring/bobby-nitro' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/motoring/bobby-nitro'
                  title='Bobby Nitro | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/current-affairs' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/current-affairs'
                  relatedSections={[{ id: 'opinion/current-affairs/bobby-brown' }, { id: 'opinion/current-affairs/munier-grootbek' }]}
                  title='Current Affairs | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/current-affairs/bobby-brown' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/current-affairs/bobby-brown'
                  relatedSections={[{ id: 'opinion/current-affairs/munier-grootbek' }]}
                  title='Bobby Brown | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/current-affairs/munier-grootbek' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/current-affairs/munier-grootbek'
                  relatedSections={[{ id: 'opinion/current-affairs/bobby-brown' }]}
                  title='Munier Grootbek | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/lifestyle' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/lifestyle'
                  relatedSections={[{ id: 'opinion/lifestyle/cooksisters' }, { id: 'opinion/lifestyle/waseef-piekaan' }, { id: 'opinion/lifestyle/auntie-pearl' }]}
                  itle='Lifestyle | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/lifestyle/cooksisters' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/lifestyle/cooksisters'
                  relatedSections={[{ id: 'opinion/lifestyle/waseef-piekaan' }, { id: 'opinion/lifestyle/auntie-pearl' }]}
                  title='Cooksisters | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/lifestyle/waseef-piekaan' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/lifestyle/waseef-piekaan'
                  relatedSections={[{ id: 'opinion/lifestyle/cooksisters' }, { id: 'opinion/lifestyle/auntie-pearl' }]}
                  title='Waseef Piekaan | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/opinion/lifestyle/auntie-pearl' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='opinion/lifestyle/auntie-pearl'
                  relatedSections={[{ id: 'opinion/lifestyle/cooksisters' }, { id: 'opinion/lifestyle/waseef-piekaan' }]}
                  title='Auntie Pearl | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/multimedia' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='multimedia'
                  title='Multimedia | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/competitions' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  section='competitions'
                  title='Competitions | The Daily Voice'
                  description='The latest Western Cape, National, International, Sport, Entertainment alternative news and opinions. The Daily Voice - Ons Skrik Vir Niks!'
                />}
            />
            <Route
              path='/test' exact render={(p) =>
                <>
                  <Helmet>
                    <meta name='robots' content='noindex, nofollow' />
                  </Helmet>
                  <DefaultSectionPage
                    {...props} {...p} isPage0={isPage0}
                    section='all-content-feed'
                    adPath='test'
                    title='Test page | The Daily Voice'
                    description='Test page for marketing purposes'
                  />
                </>}
            />
            <Route
              path='/:section([a-zA-Z0-9/-]+)' exact render={(p) =>
                <DefaultSectionPage
                  {...props} {...p} isPage0={isPage0}
                  title={getSectionLabel(url2section(props.location.pathname)) + ' | Latest News & Developments | The Daily Voice'}
                  section={url2section(props.location.pathname)} url={props.location.pathname}
                />}
            />
            <Route path='*' render={(p) => <NotFound {...props} {...p} />} />
          </Switch>
        )}
        <Route
          path='/authors/:section([a-zA-Z-]+)' render={(p) => <AuthorSectionPage
            {...props} {...p}
            isPage0={isPage0}
            section={'authors/' + p.match.params.section}
            url={props.location.pathname}
            authorSlug={p.match.params.section}
                                                              />}
        />
        <Route path={props.match.url + '/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path={props.match.url + '/:contentKey([0-9]{5,})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path={props.match.url + '/:title*([-])*:contentKey([0-9]{5,})'} render={(p) => <Article {...props} {...p} contentKey={p.match.params.contentKey} />} />
        <Route path={props.match.url + '/:section([a-zA-Z0-9-]+)'} render={(p) => <DefaultSection {...props} {...p} />} />
      </Switch>
    </>
  )
}

export default DefaultSection
