import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import Helmet from 'react-helmet'
import { PulseLoader } from 'react-spinners'

import { fetchArticle, setPreviewArticle, submitCompetition, serverArticleRender } from '../../store/article'
import { getAllFormFields } from 'ion-dom'
import IonArticle from 'ion-article'
import { ArticleMeta } from 'ion-article-cmp'
import Ad from '../components/Ad'
import { FORMATS, MAPPINGS } from '../components/AdFormats'
import Video from '../components/Video'
import WingBanners from '../components/WingBanners'
import RelatedArticles from '../components/RelatedArticles'
import { defaultWidth, Mobile, MobileElse } from 'ion-media'
import { ImageOrNoImage, ImageCalculator, calcImageSrcUrl } from 'ion-image'
import ArticleSocialShare from '../components/ArticleSocialShare'
import ArticleGallery from '../components/ArticleGallery'
import NotFound from './NotFound'
import NoImage from '../static/no-image.png'
import Sidebar from '../components/Sidebar'
import Klangoo from '../components/Klangoo'
import Outbrain from '../components/OutbrainWidget'
import Lyftitec from '../components/Lyftitec'

const LeaderboardTop = [
  [[1024, 0], [[728, 90], [970, 90]]],
  [[640, 0], [[460, 60]]],
  [[0, 0], [[320, 100], [320, 50], [300, 100], [300, 50]]]
]

const Leaderboard = [
  [[1024, 0], [[728, 90], [970, 90], [970, 250]]],
  [[640, 0], [[460, 60]]],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50]]]
]

const mpuRHS = [
  [[1024, 0], [[300, 250], [3, 3]]],
  [[640, 0], [[300, 250], [336, 280], [3, 3]]],
  [[0, 0], [[300, 600], [336, 280], [320, 100], [320, 50], [300, 250], [300, 100], [300, 50], [3, 3]]]
]
const Wing = [
  [[1024, 0], [[160, 600], [120, 600]]],
  [[640, 0], []],
  [[0, 0], []]
]

// Pulled from siteProps
export class Article extends Component {
  constructor (props) {
    super(props)
    this.state = { hasMounted: false }
    this.scriptloaded = this.scriptloaded.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.processVideo = true
    if (this.props.article && (this.props.previewArticle || this.props.article.contentKey === this.props.contentKey)) {
      this.props.serverArticleRender(this.props.article)
    }
  }

  validateEmail (email) {
    const re = /^[^.@][^@]+@{1}[^@]+\.[^@]+[^.@]$/i
    return email.match(re)
  }

  validateName (name) {
    const re = /^[`a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' -]{2,45}$/i
    return name.match(re)
  }

  validateField (field) {
    if (field.name.match('email')) {
      if (!field.value || !this.validateEmail(field.value)) {
        return 'Please a provide a valid email'
      } else {
        return true
      }
    } else if (field.name.match('fullNameShort')) {
      if (!field.value || !this.validateName(field.value)) {
        return 'Please provide a valid Name'
      } else {
        return true
      }
    } else {
      return true
    }
  }

  isUserLoggedIn () {
    return !!this.props.userToken
  }

  showErrorMessage (id, result) {
    const competitionFormNode = document.getElementById(this.props.article.competition.form)
    if (competitionFormNode) {
      const input = document.getElementById(id)
      input.insertAdjacentHTML('afterend', '<p class="form-error-validation" style="color:red">' + result + '</p>')
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    const elements = document.getElementById(this.props.article.competition.form).elements
    this.props.submitCompetition(getAllFormFields(elements))
  }

  componentDidUpdate (prevProps) {
    // Re-fetch articles when the contentKey changes
    if (this.props.article && this.props.article.previewArticle) {
      return
    }
    if (prevProps.contentKey !== this.props.contentKey ||
        (prevProps.hasError && !prevProps.isConnected && this.props.isConnected)) {
      this.props.fetchArticle(this.props.contentKey)
    }
    if (this.props.article) {
      this.scripts = 0
      this.processArticle()
    }
    if (this.props.message) {
      const errorNode = document.getElementsByClassName('form-error-validation')
      while (errorNode.length > 0) {
        errorNode[0].parentNode.removeChild(errorNode[0])
      }
      const errors = this.props.message
      Object.keys(errors).map((errElement) =>
        errors[errElement].map((message) => this.showErrorMessage('fields-' + errElement, message))
      )
    }
  }

  UNSAFE_componentWillMount () {
    // Fetch the first time this component is mounted
    if (this.props.ArticlePushContext) {
      this.props.setPreviewArticle(this.props.ArticlePushContext)
    } else {
      if (this.props.article && this.props.previewArticle) {
        return
      }
      const now = new Date()
      const lastFetch = new Date(this.props.lastFetch ? this.props.lastFetch : 0)
      // eslint-disable-next-line eqeqeq
      if (!this.props.article || this.props.contentKey != this.props.article.contentKey || now - lastFetch > (60 * 1000)) {
        this.props.fetchArticle(this.props.contentKey)
      }
    }
  }

  scriptloaded () {
    this.scripts--
    console.log('Script loaded', this.scripts, 'left to load')
    if (this.scripts === 0) {
      console.log('All scripts loaded')
      this.nodeScriptReplace(document.getElementById('article-more-body'), false)
    }
  }

  nodeScriptIs (node) {
    return node.tagName === 'SCRIPT'
  }

  nodeScriptClone (node, src, callback) {
    if (!src && node.src) {
      return node
    }
    if (src && !node.src) {
      return node
    }
    const script = document.createElement('script')
    script.text = node.innerHTML
    for (let i = node.attributes.length - 1; i >= 0; i--) {
      script.setAttribute(node.attributes[i].name, node.attributes[i].value)
    }
    if (callback) {
      return callback(script)
    }
    return script
  }

  nodeScriptReplace (node, src, callback) {
    if (!node) { return }
    if (this.nodeScriptIs(node) === true) {
      node.parentNode.replaceChild(this.nodeScriptClone(node, src, callback), node)
    } else {
      let i = 0
      const children = node.childNodes
      while (i < children.length) {
        this.nodeScriptReplace(children[i++], src, callback)
      }
    }
  }

  resizeImages (width, bodyHTML) {
    const images = bodyHTML.match(/<img [^>]+>/g)
    if (images) {
      const calc = new ImageCalculator()
      const shape = 'original'
      const resizeURL = process.env.RAZZLE_RESIZE_URL

      for (let i = 0; i < images.length; i++) {
        const image = JSON.parse(images[i]
          .replace(/['"][ ]+/g, '",')
          .replace(/([ ,])([a-z0-9-]+)=/gi, '$1"$2":')
          .replace(/<img\s+/, '{')
          .replace(/[,/ ]*>$/, '}')
        )

        let imgOffsetX = parseInt(image.offsetx) || 0
        let imgOffsetY = parseInt(image.offsety) || 0
        let imgCropWidth = parseInt(image.cropwidth) || 0
        let imgCropHeight = parseInt(image.cropwidth) || 0
        const imageWidth = parseInt(image.imageWidth)
        const imageHeight = parseInt(image.imageHeight)
        if ('imageCrop' in image) {
          const crops = image.imageCrop.split('/')
          imgOffsetX = Math.round((parseFloat(crops[0]) || 0) * imageWidth)
          imgOffsetY = Math.round((parseFloat(crops[1]) || 0) * imageHeight)
          imgCropWidth = Math.round((parseFloat(crops[2]) || 1) * imageWidth)
          imgCropHeight = Math.round((parseFloat(crops[3]) || 1) * imageHeight)
        }
        const height = calc.calcHeight(shape, width) || width * 100
        const { offsetx, offsety, cropWidth, cropHeight } = calc.getCropCoordsForShape(shape,
          width, height, imageWidth, imageHeight,
          imgOffsetX, imgOffsetY, imgCropWidth, imgCropHeight,
          0, 0)
        const src = calc.buildImageUrl(resizeURL, '', width, height, image.src, offsetx, offsety, cropWidth, cropHeight)
        bodyHTML = bodyHTML.replace(images[i], `<img class="${image.class}" src="${src}" loading="lazy" width="${width}">`)
      }
    }
    return bodyHTML
  }

  renderAuthors (article) {
    if (article.authors) {
      return article.authors.map((author, index, arr) => {
        if (arr.length - 1 === index) {
          // last one
          return <strong key={index}>{author.hasAuthorPage ? <Link to={'/' + author.slug}>  {author.name} </Link> : author.name}</strong>
        } else {
          return <strong key={index}>{author.hasAuthorPage ? <Link to={'/' + author.slug}>  {author.name}, </Link> : author.name + ', '}</strong>
        }
      })
    }
  }

  processArticle () {
    const articleNode = document.getElementById('article-more-body')
    if (articleNode) {
      this.scripts = 0
      this.nodeScriptReplace(articleNode, true, (node) => {
        this.scripts++
        node.async = false
        node.onload = this.scriptLoaded
        return node
      })
    }
    if (!this.scripts) {
      this.nodeScriptReplace(articleNode, false)
      this.scripts++
    }
    if (articleNode && this.processVideo) {
      for (const v of articleNode.querySelectorAll('video.ion-video')) {
        const sources = []
        for (const s of v.getElementsByTagName('source')) {
          sources.push({
            src: s.getAttribute('src'),
            type: s.getAttribute('type')
          })
        }
        const v2 = document.createElement('div')
        v2.style.cssText = 'clear:both;'
        let branding = {}
        try {
          if (v.hasAttribute('data-channel')) {
            branding = JSON.parse(decodeURIComponent(v.getAttribute('data-channel')))
          }
        } catch (e) {
          branding = {}
        }
        const videoRoot = createRoot(v2)
        videoRoot.render(
          <div className='video'>
            <Video
              sources={sources}
              poster={v.getAttribute('poster')}
              channel={v.getAttribute('data-channel')}
              branding={branding}
              autoplay={false}
              width='650'
              fluid
              preload='metadata'
              controls
            />
          </div>)
        v.parentElement.replaceChild(v2, v)
        console.log('Updated videojs video')
      }
      this.processVideo = false
    }
    if (this.props.article.competition) {
      const competitionFormNode = document.getElementById(this.props.article.competition.form)
      if (competitionFormNode) {
        competitionFormNode.onsubmit = this.handleSubmit
      }
    }
  }

  componentDidMount () {
    if (this.props.article) {
      this.processArticle()
    }
    this.setState({ hasMounted: true })
  }

  render () {
    // Redirect to the correct canonical
    if (this.props.checkCanonical && (this.props.canonical !== this.props.match.url)) {
      return <Redirect to={this.props.canonical} />
    }
    // Server-side render doesn't always catch the state changes, so check always
    if (typeof window === 'undefined' && this.props.canonical && (this.props.canonical !== this.props.location.pathname)) {
      return <Redirect to={this.props.canonical} />
    }
    if (this.props.hasError && !this.props.is404) {
      if (this.props.isConnected) {
        return (
          <div>
            <Helmet title='ERROR' />
            <div className='wrapper'>
              <div className='article-page'>
                <article role='contentinfo' aria-label='article' className='no-elated'>
                  <h1>Unexpected Error</h1>
                  <p>Error: {this.props.error}</p>
                </article>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <Helmet title='Offline' />
            <div className='wrapper'>
              <div className='article-page'>
                <article role='contentinfo' aria-label='article' className='no-elated'>
                  <h1>You are offline</h1>
                  <p>This article is not available as you are currently offline. Please re-establish your Internet connection and try again.</p>
                </article>
              </div>
            </div>
          </div>
        )
      }
    }
    if (this.props.is404 || (this.props.hasFetched && this.props.article)) {
      if (this.props.is404 || (!this.props.ArticlePushContext && this.props.article.titleKey !== process.env.RAZZLE_TITLE_KEY)) {
        return (
          <>
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/404`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' mapping={LeaderboardTop} {...FORMATS.leaderboardtop} />
            <NotFound />
            {this.state.hasMounted &&
              <MobileElse>
                <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/404`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard-top' mapping={LeaderboardTop} {...FORMATS.leaderboard} />
              </MobileElse>}
          </>
        )
      }
      const article = new IonArticle(this.props.article)
      const resizeURL = process.env.RAZZLE_RESIZE_URL
      const gallery = article.images && article.images.length > 1 ? article.images.slice(1) : []
      let tagsList = []
      if (article.primaryTag) {
        tagsList.push(article.primaryTag)
      }
      if (article.secondaryTags) {
        tagsList = tagsList.concat(article.secondaryTags)
      }
      const tags = tagsList.slice(0, 3)
      const published = article.formatDate()
      const mainImage = !article.videos && article.getImageObject()
      const headline = article.headline
      let imageWidth = 420
      if (defaultWidth > 500) {
        imageWidth = 610
      }
      if (defaultWidth > 1024) {
        imageWidth = 610
      }
      const competitionSlug = article.getCompetitionSlug()
      let competitionPayload = '<p>This competition has expired</p>'
      if (competitionSlug && !article.competitionExpired) {
        competitionPayload = article.competition.competition
        if (this.props.hasErrorSubmit || !this.props.hasSubmitted) {
          competitionPayload += `<script>errors=${JSON.stringify(this.props.message || false)};formData=${JSON.stringify(this.props.formData || {})}</script>`
        }
        if (this.props.hasSubmitted) {
          competitionPayload = `<form name='${competitionSlug}-form'>Thank you for your submission</form>`
        }
      }
      const socialImageSrcUrl = article.socialTeaserImage ? calcImageSrcUrl(article.socialTeaserImage, 960, '16x9', false) : undefined
      const canonicalUri = article.getCanonicalUri()
      article.bodyHTML = this.resizeImages(imageWidth, article.bodyHTML)
      article.bodyHTML = article.bodyHTML.replace('<!-- [AD_SLOT] -->', '<ad-slot/>')
      article.bodyHTML = article.setCompetition(competitionSlug, competitionPayload)
      article.bodyHTML = '<!-- C-ON- TEXT_CONTENT_START --><!--sse-->' + article.bodyHTML + '<!--/sse--><!-- C-ON- TEXT_CONTENT_END -->'
      const bodyElements = article.bodyHTML.split('<ad-slot/>')
      const relatedArticlesPresent = article.relatedArticles.length > 0
      const proposedArticles = []
      if (this.props.readNext) {
        const lengthArray = this.props.readNext.length < 9 ? this.props.readNext.length : 9
        for (let i = 0; i < lengthArray; i++) {
          // eslint-disable-next-line eqeqeq
          if (this.props.readNext[i].contentKey != this.props.contentKey) {
            proposedArticles.push(new IonArticle(this.props.readNext[i]))
          }
        }
      }
      if (proposedArticles.length > 8) {
        proposedArticles.pop()
      }
      let videoSources = []
      if (article.videos && article.videos[0].sources) {
        const sources = article.videos[0].sources
        videoSources = Object.keys(sources).map(key => {
          return { src: sources[key].url || sources[key].mp4.url, type: sources[key].type || 'video/mp4', label: key }
        })
      }
      return (
        <>
          <WingBanners useSmall={this.props.useSmall} isConnected={this.props.isConnected} location={{ pathname: `/${canonicalUri}` }} onSlotRenderEndedLeft={this.props.onSlotRenderEndedLeft} onSlotRenderEndedRight={this.props.onSlotRenderEndedRight} mappingLeft={Wing} mappingRight={Wing} />
          <div className='main-article'>
            <Helmet title={headline}>
              <meta property='fb:app_id' content='293175074032541' />
              <meta property='og:type' content='article' />
              <meta property='og:title' content={article.socialTeaserHeadline || headline} />
              <meta property='og:description' content={article.socialAbstract || article.abstract} />
              <meta property='og:url' content={this.props.canonical} />
              <meta property='keywords' content={this.props.keywords || [].concat(article.primaryTag).concat(article.secondaryTags).map(tag => tag && tag.label).join(', ')} />
              <meta property='og:image' content={resizeURL + (socialImageSrcUrl || calcImageSrcUrl(article.getImageObject(), 960, '16x9', false))} />
              <meta itemProp='headline' content={headline} />
              <meta itemProp='description' name='description' content={article.abstract} />
              <meta itemProp='dateModified' content={article.modified} />
              <meta itemProp='datePublished' content={article.published} />
              <meta itemProp='identifier' content={article.contentKey} />
              <meta name='twitter:site' content={this.props.twitterName} />
              <meta name='twitter:creator' content={this.props.twitterName} />
              <meta name='twitter:title' content={headline} />
              <meta name='twitter:description' content={article.abstract} />
              <meta itemprop='image' content={resizeURL + calcImageSrcUrl(article.getImageObject(), 960, '16x9', false)} />
              <meta itemprop='thumbnailUrl' content={resizeURL + calcImageSrcUrl(article.getImageObject(), 100, '16x9', false)} />
              <meta name='twitter:card' content='summary_large_image' />
              <meta name='twitter:image:src' content={resizeURL + (socialImageSrcUrl || calcImageSrcUrl(article.getImageObject(), 960, '16x9', false))} />
              <meta itemProp='inLanguage' content='en' />
              {article.canonicalUrl &&
                <link rel='canonical' itemprop='url' href={article.canonicalUrl} />}
            </Helmet>
            <ArticleMeta article={article} />
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${canonicalUri}`} slotId='interstitial' targeting={{ interstitial: 'interstitial' }} collapseEmptyDiv className='advert-leaderboard' anchor='interstitial' mapping={MAPPINGS.Interstitial} {...FORMATS.interstitial} />
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${canonicalUri}`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' mapping={LeaderboardTop} {...FORMATS.leaderboardtop} />
            <div className='wrapper'>
              <div className='article-page'>
                <article role='contentinfo' aria-label='article' className={(this.props.article.relatedArticles && this.props.article.relatedArticles.length ? 'no-elated' : 'no-related')}>
                  <h1><span>{headline}</span></h1>
                  <div itemProp='articleBody' className='article-body'>
                    {mainImage && mainImage.url && article.images.length === 1 &&
                      <figure>
                        <Mobile>
                          <ImageOrNoImage image={article.getImageObject()} shape='16x9' width='320' alt={mainImage.altText || article.formatImageCaption()} />
                        </Mobile>
                        {this.state.hasMounted &&
                          <MobileElse>
                            <span>
                              <ImageOrNoImage image={article.getImageObject()} shape='16x9' width='650' alt={mainImage.altText || article.formatImageCaption()} />
                            </span>
                          </MobileElse>}
                        <figcaption>{article.formatImageCaption()}</figcaption>
                      </figure>}
                    {!mainImage && article.videos && article.videos[0].sources &&
                      <div className='video'>
                        <Video
                          sources={videoSources}
                          poster={article.videos[0].thumbnailURL}
                          autoplay={false}
                          width='670'
                          fluid
                          preload='metadata'
                          channel={article.videos[0].channel}
                          branding={article.videos[0].branding}
                          controls
                        />
                      </div>}
                    <aside className='meta'>
                      <p>{this.renderAuthors(article) || article.author}&nbsp;|&nbsp;{published}</p>
                    </aside>
                    <div className='articleBodyMore' id='article-more-body'>
                      {
                        bodyElements.map((element, index) => {
                          switch (index) {
                            case 0:
                              return (
                                <div key={'div-' + index} dangerouslySetInnerHTML={{ __html: element }} />
                              )
                            case 1: // inarticle-top ad
                              return (
                                <Fragment key={index}>
                                  <div className='in-article-ad'>
                                    <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${article.getCanonicalUri()}`} slotId='Inarticle-top' targeting={{ Inarticle: 'top' }} collapseEmptyDiv className='advert-inarticle' mapping={mpuRHS} {...FORMATS.inarticle} />
                                  </div>
                                  <div key={'div-' + index} dangerouslySetInnerHTML={{ __html: element }} />
                                </Fragment>
                              )
                            case 2: // inarticle-middle ad
                              return (
                                <Fragment key={index}>
                                  <div className='in-article-ad'>
                                    <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${article.getCanonicalUri()}`} slotId='Inarticle-middle' targeting={{ Inarticle: 'middle' }} collapseEmptyDiv className='advert-inarticle' mapping={mpuRHS} {...FORMATS.inarticle} />
                                  </div>
                                  <div key={'div-' + index} dangerouslySetInnerHTML={{ __html: element }} />
                                </Fragment>
                              )
                            case 3: // Related Articles or Teads if none
                              return (
                                <Fragment key={index}>
                                  <div className='in-article-vid-ad'>
                                    {process.env.RAZZLE_ENABLE_LYTITEC && <Lyftitec />}
                                  </div>
                                  {relatedArticlesPresent &&
                                    <div id='related-articles' className='related-articles'>
                                      <RelatedArticles relatedArticles={article.relatedArticles} unknownImage={NoImage} />
                                    </div>}
                                  {!!relatedArticlesPresent &&
                                    <div className='in-article-ad'>
                                      <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${article.getCanonicalUri()}`} slotId='Inarticle-related' targeting={{ Inarticle: 'related' }} collapseEmptyDiv className='advert-inarticle' mapping={mpuRHS} {...FORMATS.inarticle} />
                                    </div>}
                                  <div key={'div-' + index} dangerouslySetInnerHTML={{ __html: element }} />
                                </Fragment>
                              )
                            case 4: // inarticle-bot ad
                              return (
                                <Fragment key={index}>
                                  <div className='in-article-ad'>
                                    <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${article.getCanonicalUri()}`} slotId='Inarticle-bot' targeting={{ Inarticle: 'bot' }} collapseEmptyDiv className='advert-inarticle' mapping={mpuRHS} {...FORMATS.mpu} />
                                  </div>
                                  <div key={'div-' + index} dangerouslySetInnerHTML={{ __html: element }} />
                                </Fragment>
                              )
                            case (index > 4 ? index : undefined): // inarticle-bot + (index - 3) ad
                              return (
                                <Fragment key={index}>
                                  <div className='in-article-ad'>
                                    <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${article.getCanonicalUri()}`} slotId={`Inarticle-bot${index - 3}`} targeting={{ Inarticle: `bot${index - 4}` }} collapseEmptyDiv className='advert-inarticle' mapping={mpuRHS} {...FORMATS.mpu} />
                                  </div>
                                  <div key={'div-' + index} dangerouslySetInnerHTML={{ __html: element }} />
                                </Fragment>
                              )
                          }
                          // Remainder of the article
                          return (
                            <div key={'div-' + index} dangerouslySetInnerHTML={{ __html: element }} />
                          )
                        })
                      }
                    </div>
                    {gallery.length > 0 &&
                      <ArticleGallery gallery={gallery} />}
                    <div className='article-tags'>
                      {tags.map((tag, index) => (
                        <Fragment key={tag.sectionSlug + '/' + tag.slug}><Link to={'/' + tag.sectionSlug + '/' + tag.slug} key={index}>{tag.label}</Link>{tags.length > index + 1 ? ' | ' : ''}</Fragment>
                      ))}
                    </div>
                    <ArticleSocialShare className='inline-share-buttons' url={typeof (window) !== 'undefined' ? window.location.href : this.props.canonical} title={headline} quote={headline} media={resizeURL + (socialImageSrcUrl || calcImageSrcUrl(article.getImageObject(), 960, '16x9', false))} />
                  </div>
                  {typeof window !== 'undefined' &&
                    <Outbrain canonical={this.props.canonical} />}
                </article>
                <Sidebar {...this.props} section={canonicalUri}>
                  <Klangoo widgetId={process.env.RAZZLE_KLANGOO_ID} article={article} />
                </Sidebar>
              </div>
            </div>
            {this.state.hasMounted &&
              <MobileElse>
                <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${canonicalUri}`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard-bot' mapping={Leaderboard} {...FORMATS.leaderboard} />
              </MobileElse>}
          </div>
        </>
      )
    } else {
      return (
        <>
          <Helmet title='Article' />
          <div className='main-article'>
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${this.props.location.pathname.substr(1)}`} slotId='leaderboard-top' targeting={{ leaderboard: 'top' }} collapseEmptyDiv className='advert-leaderboard-top' mapping={LeaderboardTop} {...FORMATS.leaderboardtop} />
            <div className='wrapper'>
              <div className='article-page'>
                <div className='loading-article'>
                  Loading article...
                  <PulseLoader color='#B10015' loading margin='10px' />
                </div>
                <Sidebar {...this.props} section={this.props.location.pathname.substr(1)} />
              </div>
            </div>
            <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${this.props.location.pathname.substr(1)}`} slotId='leaderboard-bot' targeting={{ leaderboard: 'bot' }} collapseEmptyDiv className='advert-leaderboard-bot' mapping={Leaderboard} {...FORMATS.leaderboard} />
          </div>
        </>
      )
    }
  }
}

const mapStateToProps = (state) => (Object.assign({}, state.article, state.user))
const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchArticle,
  setPreviewArticle,
  serverArticleRender,
  submitCompetition
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Article)
